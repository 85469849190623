import React from 'react';
import { Link } from 'react-router-dom';
import images from './constants/images';

const CateringPage = () => {
    return (
        <div className="bg-gray-100 min-h-screen">
            {/* Hero Section (unchanged) */}
            <div className="relative bg-gray-900 text-white py-24">
                <div className="container mx-auto px-4">
                    <h1 className="text-4xl md:text-5xl font-bold mb-6">Catering Services</h1>
                    <p className="text-xl mb-8">Explore our catering methods and catering menu below</p>
                    <div className="grid md:grid-cols-3 gap-6">
                        {/* CaterCow */}
                        <div className="relative rounded-lg overflow-hidden shadow-lg group">
                            <img src={images.cow} loading='lazy' alt="CaterCow" className="w-full h-64 object-cover transition duration-300 group-hover:scale-110" />
                            <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-end p-6">
                                <h3 className="text-2xl font-bold mb-2">CaterCow</h3>
                                <p className="mb-4">Click link below to order</p>
                                <Link to="https://www.catercow.com/catering/8451-lil-greek-kitchen/referral" className="bg-blue-600 text-white px-6 py-2 rounded-full hover:bg-blue-700 transition duration-300 text-center">
                                    Order Now
                                </Link>
                            </div>
                        </div>
                        {/* EzCater */}
                        <div className="relative rounded-lg overflow-hidden shadow-lg group">
                            <img src={images.ez} loading='lazy' alt="EzCater" className="w-full h-64 object-cover transition duration-300 group-hover:scale-110" />
                            <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-end p-6">
                                <h3 className="text-2xl font-bold mb-2">EzCater</h3>
                                <p className="mb-4">Click link below to order</p>
                                <Link to="https://www.ezcater.com/catering/pvt/lil-greek-kitchen-3" className="bg-blue-600 text-white px-6 py-2 rounded-full hover:bg-blue-700 transition duration-300 text-center">
                                    Order Now
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Greek Party Pack Details */}
            <div id="greek-party-pack" className="container mx-auto px-4 py-16 ">
                <h2 className="text-3xl font-bold mb-8">Not So Lil' Greek Party Pack</h2>
                <div className="bg-white rounded-lg shadow-lg p-6">
                    <p className="text-2xl font-bold mb-4 text-blue-600">Only $17.95 per person!</p>
                    <ul className="list-disc list-inside space-y-2 text-lg">
                        <li>Pita Setup (Kalamata olives, onion, tomato, feta, cucumber)</li>
                        <li>One choice of base (Greek lemon rice or fresh greens)</li>
                        <li>Two choices of protein (gyro, chicken gyro, grilled chicken, falafel) or steak for an extra +$1.95 per person</li>
                        <li>One choice of side (green beans, Greek potato's, or Greek salad)</li>
                        <li>Two homemade sauces (Greek dressing, tzatziki, hummus, Lil spicy Greek sauce, garlic cream)</li>
                        <li>Utensils & pitas included</li>
                    </ul>
                </div>
            </div>

            {/* Catering Menu */}
            <div id="catering-menu" className="container mx-auto px-4 py-16">
                <h2 className="text-3xl font-bold mb-8">Catering Menu</h2>
                <div className="bg-white rounded-lg shadow-lg p-6">
                    <p className="text-xl font-bold mb-6 text-blue-600">All trays feed up to 10 people!</p>
                    <div className="grid md:grid-cols-2 gap-8">
                        <div>
                            <h3 className="text-2xl font-bold mb-4">Main Dishes</h3>
                            <ul className="space-y-2">
                                <li className="flex justify-between text-lg"><span>Tray of Grilled Steak</span><span>$69.55</span></li>
                                <li className="flex justify-between text-lg"><span>Tray of Grilled Chicken</span><span>$62.55</span></li>
                                <li className="flex justify-between text-lg"><span>Tray of Gyro</span><span>$56.55</span></li>
                                <li className="flex justify-between text-lg"><span>Tray of Chicken Gyro</span><span>$56.55</span></li>
                                <li className="flex justify-between text-lg"><span>Tray of Falafel</span><span>$51.55</span></li>
                            </ul>
                            <h3 className="text-2xl font-bold mt-8 mb-4">Sides</h3>
                            <ul className="space-y-2">
                                <li className="flex justify-between text-lg"><span>Tray of Greek Potatoes</span><span>$56.55</span></li>
                                <li className="flex justify-between text-lg"><span>Tray of Greek Salad</span><span>$25.55</span></li>
                                <li className="flex justify-between text-lg"><span>Tray of Greek Lemon Rice</span><span>$22.55</span></li>
                                <li className="flex justify-between text-lg"><span>Tray of Green Beans</span><span>$21.55</span></li>
                                <li className="flex justify-between text-lg"><span>Tray of Mixed Greens</span><span>$14.95</span></li>
                            </ul>
                        </div>
                        <div>
                            <h3 className="text-2xl font-bold mb-4">Extras</h3>
                            <ul className="space-y-2">
                                <li className="flex justify-between text-lg"><span>Tray of Pitas</span><span>$12.55</span></li>
                                <li className="flex justify-between text-lg"><span>Tray of Sauce (Your choice of sauce)</span><span>$9.95</span></li>
                                <li className="flex justify-between text-lg"><span>Tray of Baklava</span><span>$51.55</span></li>
                                <li className="flex justify-between text-lg"><span>Tray of Spanakopita</span><span>$42.55</span></li>
                                <li className="flex justify-between text-lg"><span>Tray of Rizogalo</span><span>$25.95</span></li>
                            </ul>
                            <h3 className="text-2xl font-bold mt-8 mb-4">Beverages</h3>
                            <ul className="space-y-2">
                                <li className="flex justify-between text-lg"><span>Jug of Lemonade or Sweet/Unsweet Tea</span><span>$9.55</span></li>
                                <li className="flex justify-between text-lg"><span>Canned Beverages</span><span>$1.95</span></li>
                                <li className="flex justify-between text-lg"><span>Case of Canned Beverages (x25)</span><span>$25</span></li>
                            </ul>
                            <p className="text-sm mt-4 italic">Canned beverages: Pepsi, Diet Pepsi, Sierra Mist, Mt. Dew, Water...</p>
                        </div>
                    </div>
                </div>
            </div>
            {/* Individual Packaged Catering */}
            <div id="individual-packaged-catering" className="container mx-auto px-4 py-16">
                <h2 className="text-3xl font-bold mb-8">Individual Packaged Catering</h2>
                <p className="text-xl mb-6">For all your individual order catering needs...</p>
                <div className="grid md:grid-cols-3 gap-8">
                    <div className="bg-white rounded-lg shadow-lg p-6">
                        <img src={images.hold} alt="Greek Bowl" className="w-full h-48 object-cover rounded-lg mb-4" />
                        <h3 className="text-2xl font-bold mb-2">Bowls</h3>
                        <p className="mb-4">Comes with your own half a pita, tzatziki, a bowl filled with Greek lemon rice or spring mix, Greek salad, and your choice of protein.</p>
                        <p className="text-xl font-bold text-blue-600">$12.00</p>
                    </div>
                    <div className="bg-white rounded-lg shadow-lg p-6">
                        <img src={images.pitaShot} alt="Greek Pita" className="w-full h-48 object-cover rounded-lg mb-4" />
                        <h3 className="text-2xl font-bold mb-2">Pitas</h3>
                        <p className="mb-4">Comes with a warm pita topped with your choice of protein, tzatziki, onion, and tomato.</p>
                        <p className="text-xl font-bold text-blue-600">$10.50</p>
                    </div>
                    <div className="bg-white rounded-lg shadow-lg p-6">
                        <img src={images.cateringpic} alt="Catering Spread" className="w-full h-48 object-cover rounded-lg mb-4" />
                        <h3 className="text-2xl font-bold mb-2">Custom Orders</h3>
                        <p className="mb-4">Need something specific? Contact us for custom individual catering options tailored to your event.</p>
                        <Link to="/contact" className="bg-blue-600 text-white px-6 py-2 rounded-full hover:bg-blue-700 transition duration-300 inline-block">
                            Contact Us
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CateringPage;