import React, { useState } from 'react';
import { FaCalendarAlt, FaMapMarkerAlt, FaInfoCircle, FaUtensils } from 'react-icons/fa';
import images from './constants/images';

// Use a single menu image for all events
const MENU_IMAGE = images.menu;

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    imageSrc: string;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, imageSrc }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
            <div className="bg-white p-4 rounded-lg max-w-3xl max-h-[90vh] overflow-auto">
                <div className="flex justify-end">
                    <button onClick={onClose} className="text-2xl">&times;</button>
                </div>
                <img src={imageSrc} alt="Menu" className="max-w-full h-auto" />
            </div>
        </div>
    );
};

interface Event {
    business: string;
    date: string;
    location: string;
    description: string;
    link?: string;
}
const PopupEventsPage: React.FC = () => {
    const [modalOpen, setModalOpen] = useState<boolean>(false);

    const events = [
        {
            business: 'Skeleton Key Brewery',
            date: 'January 10, 2025',
            location: 'Lemont',
            description: 'Join us for a special Greek pop-up event!'
        },
        {
            business: 'Skeleton Key Brewery',
            date: 'February 7, 2025',
            location: 'Lemont',
            description: 'Join us for a special Greek pop-up event!'
        },
        {
            business: 'Noon Whistle Brewing',
            date: 'February 16, 2025',
            location: 'Lombard',
            description: 'Join us for a special Greek pop-up event!'
        },
        {
            business: 'Noon Whistle Brewing',
            date: 'March 2, 2025',
            location: 'Lombard',
            description: 'Join us for a special Greek pop-up event!'
        },
        {
            business: 'Skeleton Key Brewery',
            date: 'March 7, 2025',
            location: 'Lemont',
            description: 'Join us for a special Greek pop-up event!'
        },
        {
            business: 'Noon Whistle Brewing',
            date: 'March 16, 2025',
            location: 'Lombard',
            description: 'Join us for a special Greek pop-up event!'
        },
        {
            business: 'Skeleton Key Brewery',
            date: 'April 11, 2025',
            location: 'Lemont',
            description: 'Join us for a special Greek pop-up event!'
        },
    ];

    const renderDescription = (event: Event) => {
        if (event.link) {
            const [description, linkText] = event.description.split(event.link);
            return (
                <>
                    {description}
                    <a
                        href={event.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 hover:underline"
                    >
                        {event.link}
                    </a>
                    {linkText}
                </>
            );
        }
        return event.description;
    };

    return (
        <div className="bg-gray-100 min-h-screen">
            {/* Hero Section */}
            <div className="bg-gray-900 text-white py-16">
                <div className="container mx-auto px-4 text-center">
                    <h1 className="text-4xl font-bold mb-4">Upcoming Pop-up Events</h1>
                    <p className="text-xl mb-8">Join us for delicious Greek cuisine at these special events!</p>
                </div>
            </div>

            {/* Events List */}
            <div className="container mx-auto px-4 py-16">
                <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
                    {events.map((event, index) => (
                        <div key={index} className="bg-white rounded-lg shadow-lg overflow-hidden">
                            <div className="p-6">
                                <h3 className="text-2xl font-bold mb-4 text-blue-600">{event.business}</h3>
                                <div className="flex items-center mb-3">
                                    <FaCalendarAlt className="text-xl text-blue-500 mr-3" />
                                    <p className="text-large font-bold text-gray-700">{event.date}</p>
                                </div>
                                <div className="flex items-center mb-3">
                                    <FaMapMarkerAlt className="text-xl text-blue-500 mr-3" />
                                    <p className="text-large font-bold text-gray-700">{event.location}</p>
                                </div>
                                <div className="flex items-start mb-4">
                                    <FaInfoCircle className="text-xl text-blue-500 mr-3 mt-1" />
                                    <p className="text-gray-600">{renderDescription(event)}</p>
                                </div>
                                <div className="flex items-start mb-4">
                                    <button
                                        onClick={() => setModalOpen(true)}
                                        className="inline-flex items-start text-blue-500 hover:text-blue-700"
                                    >
                                        <FaUtensils className="text-xl text-blue-500 mr-3 mt-1" />
                                        View Menu
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {/* Call-to-Action */}
            <div className="bg-white py-16">
                <div className="container mx-auto px-4 text-center">
                    <h2 className="text-3xl font-bold mb-4">Don't Miss Out!</h2>
                    <p className="text-xl mb-8">Stay updated on all our pop-up events and exclusive offers.</p>
                    <div className="flex max-w-md mx-auto">
                        <input
                            type="email"
                            placeholder="Enter your email"
                            className="flex-grow px-4 py-2 rounded-l-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                        <button className="bg-blue-500 text-white px-6 py-2 rounded-r-full hover:bg-blue-700 transition duration-300">
                            Subscribe
                        </button>
                    </div>
                </div>
            </div>

            {/* Modal */}
            <Modal
                isOpen={modalOpen}
                onClose={() => setModalOpen(false)}
                imageSrc={MENU_IMAGE}
            />
        </div>
    );
};

export default PopupEventsPage;