import React from 'react';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import images from './constants/images';
import ReviewsCarousel from './ReviewsAccordion';

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    imageSrc: string;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, imageSrc }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
            <div className="bg-white p-4 rounded-lg max-w-3xl max-h-[90vh] overflow-auto">
                <div className="flex justify-end">
                    <button onClick={onClose} className="text-2xl">&times;</button>
                </div>
                <img src={imageSrc} alt="Welcome" className="max-w-full h-auto" />
            </div>
        </div>
    );
};

const LandingPage = () => {
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        setModalOpen(true);
    }, []);

    return (
        <div className="flex flex-col min-h-screen">
            <Modal
                isOpen={modalOpen}
                onClose={() => setModalOpen(false)}
                imageSrc={images.popup}
            />

            {/* Hero Section */}
            <div className="relative bg-gray-900 h-screen flex items-center justify-center">
                <img src={images.cateringpic} alt="Greek cuisine"
                    sizes="100vw"
                    width={1920}
                    height={1080}
                    loading='lazy'
                    className="absolute inset-0 w-full h-full object-cover opacity-50" />
                <div className="relative z-10 text-center text-white">
                    <h1 className="text-5xl font-bold mb-2">Cater with Lil' Greek</h1>
                    <p className="text-xl mb-8">Click below to view our catering services.</p>
                    <Link to="/catering" className="bg-blue-500 text-white-900 px-6 py-3 rounded-full font-semibold hover:bg-blue-400 transition duration-300">
                        Cater Now
                    </Link>
                </div>
            </div>

            {/* Featured Sections */}
            <div className="container mx-auto px-4 py-16">
                <div className="grid md:grid-cols-2 gap-12">
                    {/* Catering Services */}
                    <div className="bg-white rounded-lg shadow-lg overflow-hidden">
                        <img src={images.hold} loading='lazy' alt="Catering service" className="w-full h-64 object-cover" />
                        <div className="p-6">
                            <h2 className="text-2xl font-bold mb-2">Individually Packaged Catering</h2>
                            <p className="text-gray-600 mb-4">Individually packaged catering also available.</p>
                            <Link to="/catering" className="text-blue-500 font-semibold hover:text-blue-600">
                                Learn More →
                            </Link>
                        </div>
                    </div>
                    {/* Special Dish */}
                    <div className="bg-white rounded-lg shadow-lg overflow-hidden">
                        <img src={images.logo} loading='lazy' alt="Special dish" className="w-full h-64 object-cover" />
                        <div className="p-6">
                            <h2 className="text-2xl font-bold mb-2">Have Any Questions?</h2>
                            <p className="text-gray-600 mb-4">We'd love to answer any questions you have!</p>
                            <Link to="/contact" className="text-blue-500 font-semibold hover:text-blue-600">
                                Contact Us →
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            {/* Reviews Section */}
            <div className="bg-gray-100 py-16">
                <div className="container mx-auto px-4">
                    <ReviewsCarousel />
                </div>
            </div>
        </div>
    );
};

export default LandingPage;